<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="info">
        <img src="@/assets/ps.png" />
        <div class="detail">
          <div class="name">{{data.nick_name}}</div>
          <div class="tel">{{data.mobile}}</div>
        </div>
      </div>
      <div class="list">
        <div class="listitem" @click="onScan">
          <img src="@/assets/c1.png" class="icon" />
          <div class="text">扫一扫</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>
      <div v-if="typeflag">
        <div class="list list2">
          <div class="listitem">
            <img src="@/assets/c3.png" class="icon" />
            <div class="text">所在场所</div>
            <div class="right">{{data.site_code}}</div>
          </div>
          <div class="listitem">
            <img src="@/assets/c2.png" class="icon" />
            <div class="text">负责岗位</div>
            <div class="right">{{data.client_id}}</div>
          </div>
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" @click="onMyIntegral">
          <img src="@/assets/jf.png" class="icon" />
          <div class="text">我的积分</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" @click="onTravelersManage">
          <img src="@/assets/cxgl.png" class="icon" />
          <div class="text">出行人管理</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>    
      <div class="list list2">
        <div class="listitem" @click="onTravelersQuery">
          <img src="@/assets/cxjl.png" class="icon" />
          <div class="text">出行记录</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div> 
      <div v-if="data.type === '98'">
      <div class="list list2">
        <div class="listitem" @click="onPlaceList">
          <img src="@/assets/csbj.png" class="icon" />
          <div class="text">场所维护</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div> 
      </div>
      
      
      
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      data: {
        // 1-管理员 2-负责人 3-游客
        types: '3'
      },
      typeflag:false,
      hrefurl:'',
      openid:''
    };
  },
  methods: {
    onScan() {
     
      window.wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode', 'barCode'],
        success: res => {
          let result = res.resultStr;
          this.scanResult(result);
        },
        error: res => {
          Dialog({ message: res });
        }
      });
    },
    async scanResult(code) {
      let params = qs.parse(code.split('?')[1]);
      let { SiteCode, ClientID } = params || {};
      if (SiteCode && ClientID) {
          let { data } = await this.axios.post(
            '/ctid/TldjService/dj_bdby',
            qs.stringify({
              stie_code:SiteCode,
              client_id:ClientID
            })
          );
          if (data.flag === '0') {
            Toast(data.message);
            //this.$router.replace('/assistregister/register_1?hrefurl='+this.hrefurl);
          }else{
            Toast(data.message);       
          }
      } else {
          Dialog('二维码解析失败');
      }
    },
    async onMyIntegral() {
      Toast('功能尚未开放！');
      /*let { data } = await this.axios.post(
        '/ctid/TlZhService/pz_pddyj',
        qs.stringify({})
      );
      if (data.flag === '0') {
        //Toast(data.message);
        this.$router.replace('/assistregister/register_1?hrefurl='+this.hrefurl);
      }else if(data.flag === '2') {
        //Toast(data.message);
        this.$router.replace('/assistregister/bind_print?hrefurl='+this.hrefurl);
      }else {
        Toast(data.message);       
      }*/
    },
    async onTravelersManage() {
      //this.$router.replace('/tourist/travelers_list');
      window.location.href = "http://ywfwpt.ywkj.online/ctid/TldjService/dj_face?openid="+this.openid;
    },
    async onTravelersQuery() {
      this.$router.replace('/tourist/travelers_query');
    },
    onPlaceList() {
      this.$router.replace('/maintain/place_list');
    },
    async getType() {
      /*let { data } = await this.axios.post(
        '/ctid/tlService/usertypes',
        qs.stringify({})
      );
      if (data.flag === '1') {
        this.typeflag = true;    
      }
      this.typeflag = true;*/ 
    }
  },
  async created() {
    this.openid = window.sessionStorage.getItem('openid');
    //this.getType();

    this.hrefurl = window.sessionStorage.getItem('hrefurl');
    let url = '';
    let ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      // alert(this.hrefurl);
      url = this.hrefurl;
    }else{
      url = location.href;
    }

    // this.hrefurl = location.href;
    // window.sessionStorage.setItem('hrefurl', this.hrefurl);
    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: url
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
    let { data } = await this.axios.get('/ctid/TldjService/dj_loadcustomer');
    /*window.sessionStorage.setItem('userinfo', JSON.stringify(data.data));
    this.data = data.data;*/

    var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
    var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 

    window.sessionStorage.setItem('userinfo', JSON.stringify(testobj));
    this.data = testobj;


  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 460px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
  }
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>